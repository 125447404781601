
.btn {
  // Default red button
  .btn-base();
  .btn-red(@blue-500);

  // White btn

  &.white {
    .btn-bord-mix();

    &.small {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    &.no-bords {
      border: none;
    }

    &.tr-edit {
      margin: 0;
      width: 24px;
      height: 24px;
      background-size: 12px 12px;
      background-position: center;
      background-color: transparent;
      border-color: transparent;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 4px;

      &:hover {
        border-color: @grayLight;
      }
    }
  }
  &.white_simple {
    .btn-bord-simple-mix();
    background-image: none;
    text-decoration: none !important;
    font-weight: 400;
    height: 26px;
    border-radius: 4px;

    &.small {
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 1;
      font-size: 12px;
    }

    &.no-bords {
      border: none;
    }
  }
  &.btn_gray {
    background: @grayBgBtn;
    color: @grayLight;
    padding: 7px 16px;

    &:hover {
      color: @blue-500;
    }
  }
  &.btn_gray-border {
    background: none;
    border: 1px solid @gray8;
    color: @blue-500;
    padding: 6px 28px;

    &:hover {
      color: @blue-500;
    }
  }
  &.btn_is-next {
    margin-right: 13px;
  }

  a& {
    border: 0 none;
    outline: 0 none;
  }

  &-group {
    &-flex {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .btn {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &_full {
    border-radius: 3px;
    background-color: @grey-100;
    box-shadow: 0 1px 8px 0 rgba(76, 19, 19, 0.08);
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 58px !important;
    line-height: 42px;
    color: @black;
    font-size: 14px;
    text-align: center;
    border: none;
    margin-top: 8px;

    &.white.btn,
    &.white_simple.btn {
      border:none;
    }

    .icon-new, .icon-load {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  // Outlined

  &_outlined {
    padding: 7px 20px;
    background: transparent;
    border: 1px solid white;
    font-weight: 500;

    &:hover,
    &:active {
      background: transparent;
    }
  }

  &_full-add {
    display: block;
    font-size: 13px;
    line-height: 1.71;
    color: @grayDark !important;
    padding: 20px;
    text-align: center;
    background-color: @grayBg;
    background-image: none;
    height: auto;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08));
    }

    &:hover {
      background-color: white;
    }

    .red-loader {
      vertical-align: middle;
    }

  }

  &_kassa {
    min-width: 149px;
    margin-right: 13px !important;
    text-align: center;
  }

  &_success-simple {
    .icons__success();
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-color: @messageGreen;
    padding-left: 30px;
    background-size: 14px;

    &:hover {
      background-color: @green-200;
    }

    &:active {
      background-color: @green-600;
    }
  }
  &_success {
    background-image: @greenGradient;

    &:hover {
      background-image: none;
      background: @green-500;
    }
    &:active {
      background-image: none;
      background-color: @green-600;
    }

    &.disabled {
      background: @grey-200 !important;

      .btn-content_save::before,
      .btn-content_done::before {
        .icon-animate-loading();
      }
    }
  }
  &_md {
    height: 48px;
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;

    .btn-content_icon::before {
      width: 24px;
      height: 24px;
    }
  }

  &_min-icon {
    width: 24px;
    height: 24px;
    font-size: 0;
    padding: 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      .base-icon-bg(@size:16px)
    }

    &_add::before {
      .icon-actions-add(@green-600);
    }
    &_all-done::before {
      .ui_all_done;
    }
  }

  &_refresh {
    padding-right: 10px;
    padding-left: 30px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 12px;
      height: 10px;
      .icon-refresh-btn;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &_refresh-load::before {
    .animation(spin 1s linear infinite);
  }

  &_icon {
    display: inline-flex;
    align-items: center;
    fill: #fff;
  }

  &_clear,
  &_done {
    position: relative;
    padding: 0;
    width: 30px;

    &::before {
      .icons_svg();
      .icon-done(#fff);
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -@spacing-xs 0 0 -@spacing-xs;
      width: 16px;
      height: 16px;
    }

    &.btn_size_m {
      width: 36px;

      &::before {
        margin: -12px 0 0 -12px;
        width: 24px;
        height: 24px;
      }
    }
  }

  &_done {
    &::before {
      .icon-done(#fff);
    }
  }

  &_clear {
    &::before {
      .icon-clear(@red-600);
    }
  }

  &_print {
    position: relative;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    fill: @black-500;

    .btn__icon {
      width: 28px;
      height: 28px;
    }

    .inline-tooltip {
      top: calc(100% + 4px);
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      .inline-tooltip {
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%);
      }
    }
  }

  &_size_s {
    height: 30px;
  }

  &_size_m {
    height: 36px;
  }

  &__icon {
    fill: inherit;
  }

  &_form_square {
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &.btn_size_m {
      width: 36px;

      .svg-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.btn-content {
  display: flex;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &_icon {
    margin-left: -8px;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-left: 0 !important;
      float: none !important;
      top: 0 !important;
    }
  }
  &_save::before {
    .icon-save-white;
  }
  &_new::before {
    .icon-add-circle-outlined();
  }
  &_edit-pen::before {
    .icon-edit-pen;
  }
  &_edit-black-after {
    margin-left: 0;
    margin-right: -8px;

    &::before {
      display: none;
    }
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      margin-left: 8px;
      background-size: cover;
      .icon-edit-pen(@black-500);
    }
  }
  &_cart::before {
    .icon-shopping-basket-outlined;
  }
  &_back::before {
    .icon-arrow_large_backward;
  }
  &_add::before {
    .icon-actions-add(#ffffff);
  }
  &_done::before {
    .icon-done(#fff);
  }
}
.btn-link {
  .btn-mix();
  .btn-none();
  color: @link;
  .norm-hov();

  &_gray {
    color: @grayDark;
    border-bottom: 1px dotted;
  }

  &_dotted {
    border-bottom: 1px dotted @blue-500;

    &:hover {
      border-bottom-color: @blue-500;
    }
  }
}

/* ! COMMON */

.icon-ok {
  .icon-ok();
}

.icon-cancel {
  .icon-cancel();
}

.icon-new, .icon-edit, .icon-load {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 8px;
  top: 1px;
  .center-ico();

  &:before {
    .icons_svg(block);
    .icons__plus-22;
    width: 100%;
    height: 100%;
  }
  // .plus-mix(@bg:#95d042,@height:3px,@width:11px);

  &_left {
    margin-left: 0;
    margin-right: 8px;
  }

  &.big {
    width: 12px;
    height: 12px;
  }
}

.icon-edit:before {
  .icons__edit;
}

.icon-load:before {
  .icon-load-01;
}

.icon-edit-pen {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 7px;
  top: -1px;
  float: right;
  .center-ico();

  &:before {
    .icons_svg(block);
    .icons__pencil_blue;
    width: 100%;
    height: 100%;
  }
}

.icon-times {
  display: inline-block;
  width: 11px;
  height: 11px;
  .ico-times(@blue-500, 100%);
}

.add-icon {
  padding-left: 15px;
  // .plus-mix(@bg:#95d042,@height:3px,@width:11px);
  // .plus-mix-left(@height:3px,@width:11px);
  .left-ico();

  &:before {
    .icons_svg(block);
    .icons__plus-22;
    width: 10px;
    height: 10px;
  }

  &.complete-link.loading {
    &:after {
      display: none;
    }

    &:before {
      background: none;
      border: 2px solid @grayVeryLight;
      border-top-color: @blue-500;
      .border-radius(.5em);
      .box-sizing(border-box);
      .animation(spin 1s linear infinite);
    }
  }
}

.complete-link {
  text-decoration: none;

  span {
    border-bottom: 1px dotted;
  }
  &:hover {
    text-decoration: none;

    span {
      border-bottom: 1px solid;

    }
  }

  &_cursor {
    cursor: pointer;
  }
}

.target-link {
  position: relative;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 9px;
    right: 9px;
    width: 7px;
    height: 7px;
    border-style: solid;
    border-color: rgba(72, 83, 89, 1);
  }

  &:before {
    border-width: 1px 1px 0 0;
  }

  &:after {
    border-width: 1px;
    margin-top: 3px;
    margin-right: 3px;
  }
}

.tr-delete {
  float: right;
  .icons_svg();
  .icon-delete-outlined(@grey-700);
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 7px 0 0 0;

  &:hover {
    .icon-delete-outlined(@red-500);
    opacity: 1;
  }

  &.contrast {
    opacity: 0;

    tr:hover & {
      opacity: 1;
    }
  }
}

// Корзина в .photo-popup

.basket-white {
  .icons_svg();
  .icons__del_little_white;
  width: 13px;
  height: 14px;
}

.icon-complete {
  .icons_svg(inline-block);
  .icons__checkbox_black;
  margin-right: 3px;
  position: relative;
  top: 3px;
  width: 23px;
  height: 15px;
}

.tr-null {
  float: right;
  display: block;
  width: 16px;
  height: 16px;
}

.tr-edit, .btn.tr-edit {
  .icons_svg();
  .icon-edit-pen(@grey-700);
  background-size: 16px 16px !important;
  background-repeat: no-repeat;
  float: right;
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin: 10px 0 0 0;

  &:hover {
    .icon-edit-pen(@blue-500);
    background-color: @blue-100;
    border-color: transparent !important;
    background-repeat: no-repeat !important;
  }
  &:active {
    border-color: @blue-500 !important;
  }
}

.list-export {
  a& {
    padding-left: 20px;
    .left-ico();

    &:before {
      .icons_svg();
      .icons__download;
      width: 13px;
      height: 14px;
    }
  }

  .dropdown & {
    text-decoration: none;
    border-bottom: none;
  }
}

.record-download {
  display: inline-block;
  padding-left: 20px;
  .left-ico();

  &__text {
    color: @gray;
    font-size: 12px;
  }

  &:before {
    .icons_svg();
    .icons__download_blue;
    width: 13px;
    height: 14px;
    bottom: 2px;
  }

  &:hover:before {
    .icons__download_hover;
  }
}

.burger-btn {
  .ico {
    .ico-burger(@mar:4px);
  }

  &:hover .ico {
    &, &:before, &:after {
      background-color: @hover;
    }
  }

  .dropdown > & {
    border-bottom: none;
  }
}

#apply-button {
  .dropdown__block();
  height: 55px;
  position: absolute;
  left: 100%;
  margin-left: 10px;
  top: -10px;
  padding: 9px;

  .apply {
    .btn-green();
    font-size: 12px;

    &:hover {
      background: @blue-500;
    }
  }
}

.filter-clear-all, .filter-select-all {
  .filter-cancel-btn()
}

.action-icon {
  .icons_svg(inline-block);
  .icons__action();
  width: 19px;
  height: 19px;
  vertical-align: baseline;

  &_gray {
    .icons__action_gray();
  }
}

.history-icon {
  .icons_svg(inline-block);
  .icons__history_13x13_blue;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;

  &:hover {
    .icons__history_13x13_hover;
  }

  &:active, &.active {
    .icon-sctions-time;
  }
}

.action-btn {
  color: @link;
  .btn-mix();

  &__icon, &__text {
    vertical-align: middle;
  }

  &__icon {
    margin-top: 0;
  }

  &__icon + &__text {
    margin-left: 5px;
    position: relative;
  }

  &:hover {
    .action-btn__text {
      text-decoration: underline;
      color: @hover;
    }
  }
}

.history-btn {
  .btn-mix();
  color: @link;

  &__icon, &__text {
    vertical-align: middle;
  }

  &__icon {
    margin-top: 0;

    &_14 {
      width: 14px;
    }
  }

  &__icon + &__text {
    margin-left: 5px;
    position: relative;
  }
  &__text {
    &_inner {
      font-size: 12px;
      border-bottom: none !important;
    }
  }

  &, &:hover {
    .history-icon {
      .icon-sctions-time;
    }
  }
  &:hover {
    .history-btn__text {
      text-decoration: underline;
      color: @hover;
    }
  }
}

.message-indic, .task-tbl-commentary {
  .icons_svg();
  .icons__message-2-0();
  width: 15px;
  height: 13px;
  margin: 20px 10px 0px 0px;
  position: relative;
  cursor: pointer;

  &.small {
    width: 13px;
    height: 13px;
    margin: 4px 0 0;
  }

  &.inactive {
    .icons__message-add();

    &:hover {
      .icons__message-add(@blue-500)
    }
  }

  &:hover {
    .icons__message-2-0(@blue-500);
  }

  &.inventories {
    .norm-hov();

    &, &:hover {
      background: none;
    }
    width: auto;
    height: auto;
    margin: 0;
    display: inline-block;
    border-bottom: 1px dotted;

    .popup-comment-box {
      top: 100%;
      right: 0;
      width: 287px;
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      &:after {
        right: 13px;
        top: -6px;
        .rotate(-45deg);
      }
    }

    .popup-table td {
      padding: 10px 0;
      color: @black;
    }

    .popup-table_product td {
      white-space: normal;
    }
  }

  .print & {
    display: inline-block;
    background: none;
    width: auto;
    height: auto;
    margin: auto;
    position: static;
    cursor: auto;

    &:hover {
      height: auto;
    }

    .popup-comment-box {
      top: auto;
      right: auto;
      margin: 5px 0 0 -17px;

      &:after {
        content: none;
      }
    }
  }
}

// Удалить

.del-btn, .delete-button {
  .btn-mix();
  .btn-bord-mix(transparent);
  .btn-square(36px);
  .center-ico();
  padding: 0;
  text-shadow: none;
  .box-shadow(none);

  &:before {
    .icons_svg();
    .icon-delete-outlined(@red-500);
    width: 24px;
    height: 24px;
  }
}

.delete-attachment {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 3px 0px;
  vertical-align: top;
  .center-ico();

  &:before {
    .icons_svg(block);
    .icons__del_little;
    width: 14px;
    height: 16px;
  }

  &:hover:before {
    .icons__del_little_hover;
  }
}

.del {
  .icons_svg(block);
  .icons__del_big;
  width: 14px;
  height: 16px;

  &_gray {
    .icons__del_big_gray;

    &:hover {
      .icons__del_big;
    }
  }

  &_gray_full {
    .icons__del_big_gray;
  }
}

// Влево, Вправо

.nav__btn {
  .btn-base();
  .btn-bord-mix(transparent);
  height: 30px;
  line-height: 30px - 1;
  padding: 0 15px;
  vertical-align: middle;
  position: relative;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    .chevron-css(@blue-500, 1px, 9px);
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  &_left {
    padding-left: 30px;

    &:before {
      .chevron-dir-left();
      left: 15px;
    }

    &.nav__btn_empty:before {
      left: 3px;
    }
  }

  &_right {
    padding-right: 30px;

    &:before {
      .chevron-dir-right();
      right: 15px;
    }

    &.nav__btn_empty:before {
      right: 3px;
    }
  }

  &_empty {
    width: 30px;
    padding: 0;
    .text-hide();

    &:before {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Иконка телефона

.phone-icon {
  .btn-mix();
  .btn-bord-mix(@bord:@inputBord);
  .btn-square();
  position: relative;

  &__icon {
    .abs-center-ins();
    .icons_svg();
    .icons__call_out;
    width: 16px;
    height: 16px;
  }

  &:hover &__icon {
    .icons__call_out_hover;
  }

  table td & {
    // float: left;
    // margin: 0px 0 0 -35px;
    display: inline-block;
    vertical-align: middle;
    border: none;
    // width: 16px;
    // height: 16px;

    &, &:hover, &:active, &.disabled, &.disabled:hover {
      background: none;
    }
  }

  &.blink-animation {
    .animation(none);
  }

  &.blink-animation &__icon {
    .animation(blinking 1200ms ease 3);
  }

  &_type-2 {
    border: none;
    .border-radius(0);

    &, &:hover, &:active, &.disabled, &.disabled:hover {
      background: none;
    }
  }
}

// Добавить телефон

.add-additional-phone {
  .btn-mix();
  .btn-bord-mix();
  .btn-plus-mix();
}

.btn_plus {
  .btn-plus-mix();
}

// Кнопка в таблице заказов с группой элементов

.btn-nested {
  min-width: 56px;
  max-width: 100%;
  padding: 4px 10px;
  position: relative;
  z-index: 1;
  .ellipsis();
  .btn-bord-simple-mix(white, @bord, @gray);
  background: white;
  border: 1px solid @bord;
  height: 24px;
  font-weight: 400;
  font-size: 12px;
  border-radius: 3px;
  line-height: 14px;

  &__wrap {
    position: relative;
    padding-left: 4px;
    max-width: 76px;
    text-align: left;
    display: inline-block;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      border: 1px solid @bord;
      background-color: white;
      z-index: 0;
      .border-radius(3px);
    }

    &:after {
      margin-left: 2px;
    }
  }
}

// Больше / меньше

.counts__wrapper {
  margin-top: 5px;
  white-space: nowrap;
  clear: both;
  text-align: center;
  width: @inputMinW;
}

.min-count, .max-count {
  .icons_svg(inline-block);
  .text-hide();
  vertical-align: middle;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.max-count {
  .icons__plus-30;
  margin-left: 11px;

  &:hover {
    .icons__plus_hover;
  }
}

.min-count {
  .icons__minus;

  &:hover {
    .icons__minus_hover;
  }
}

// Вверх, вниз

.sortable-control {
  .change-position-btn();

  &[value="up"] {
    .change-position-btn_up();
  }

  &[value="down"] {
    .change-position-btn_down();
  }

  tr:first-of-type & {
    &[value="up"] {
      visibility: hidden;
    }
  }

  tr:nth-last-of-type(2) & {
    &[value="down"] {
      visibility: hidden;
    }
  }
}

/* ordering */

.ordering {
  margin-right: 10px;
  // width: 75px;

  .button-ordering {
    .change-position-btn();

    &.up {
      .change-position-btn_up();
    }

    &.down {
      .change-position-btn_down();
    }
  }
}

.tr-view {
  display: block;
  text-decoration: none;
  width: 100%;
  height: 20px;
  margin-right: 20px;
  .center-ico();

  &:before {
    .icons_svg(block);
    .icons__new_window;
    width: 12px;
    height: 12px;
  }

  &:hover:before {
    .icons__new_window_hover;
  }
}

.tr-view-site, .tr-view-crm {
  .icons_svg;
  text-decoration: none;
  margin-left: 5px;
}

.tr-view-site {
  .icons__open_on_site;
  width: 11px;
  height: 10px;

  &:hover {
    .icons__open_on_site_hover;
  }
}

.tr-view-crm {
  .icon-warehouse(@blue-500);
  width: 13px;
  height: 13px;

  &:hover {
    .icon-warehouse(@red-500);
  }
}

.slide-icon {
  float: left;
  .ico-burger(@width:12px, @height:1px, @mar:3px, @marR: 5px);
}

.thumb-up-ico { // https://yadi.sk/i/b-QtznGhoCTz7
  width: 24px;
  height: 24px;
  .icons_svg(inline-block);
  .icons__like;
}

.accompanying-logo { // https://yadi.sk/i/QJTtda66oCU6m
  width: 77px;
  height: 12px;
  display: inline-block;
}

/* segment icons */
// segments, Главная → Лояльность → Скидки

.criteria-close {
  width: 20px;
  height: 20px;
  .icon-close-btn(@grayLight);
  opacity: .5;
  position: absolute;
  top: 20px;
  right: 15px;
  background-size: cover;

  &:hover {
    opacity: 1;
  }
}

.criteria-del-btn {
  width: 11px;
  height: 14px;
  .icons_svg(inline-block);
  .icons__del_little;

  &:hover {
    .icons__del_little_hover;
  }
}

.new-tab, .criteria-simple-form > .table .item.criteria-title a[target="_blank"] {
  position: relative;
  padding-right: 20px;
  line-height: 20px;

  &:after {
    content: '';
    width: 13px;
    height: 12px;
    position: absolute;
    bottom: 3px;
    right: 0;
    .icons_svg(block);
    .icons__new_window;
  }

  &:hover:after {
    .icons__new_window_hover;
  }
}

.new-tab {
  margin-left: 10px;
}

.segment-folder:before {
  content: '';
  margin-right: 8px;
  width: 12px;
  height: 10px;
  .icons_svg(inline-block);
  .icons__folder;
}

/* Tumbler */

input[name*="byExists"], input[name*="negated"] {
  float: right;
}

.style-tumbler-wrap {
  float: right;
  border: 1px solid @grey-500;
  border-radius: 3px;
  white-space: nowrap;
  padding: 6px 10px;
  line-height: 1.2;
  font-size: 12px;

  .tumbler-item {
    color: @gray;
    font-size: 11px;

    &.active {
      color: @black;
      cursor: default;
    }
  }
}

.style-tumbler-wrap .tumbler-item, .style-tumbler {
  vertical-align: middle;
  cursor: pointer;
}

.style-tumbler {
  width: 36px;
  position: relative;
  height: 16px;
  display: inline-block;
  margin: 0 10px;
  background: @lightOrange;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZiOTU3NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjlkOTMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, @lightOrange 0%, @lightPink2 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @lightOrange), color-stop(100%, @lightPink2));
  background: -webkit-linear-gradient(top, @lightOrange 0%, @lightPink2 100%);
  background: -o-linear-gradient(top, @lightOrange 0%, @lightPink2 100%);
  background: -ms-linear-gradient(top, @lightOrange 0%, @lightPink2 100%);
  background: linear-gradient(to bottom, @lightOrange 0%, @lightPink2 100%);
  .border-radius(8px);
  .user-select(none);

  &.disabled {
    background: @grayLight;
  }

  &:after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 2px;
    left: 2px;
    .box-shadow(inset 0 0 3px rgba(255, 255, 255, .28));
    background: white;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkN2UwZGEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, white 0%, @grey-500 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, @grey-500));
    background: -webkit-linear-gradient(top, white 0%, @grey-500 100%);
    background: -o-linear-gradient(top, white 0%, @grey-500 100%);
    background: -ms-linear-gradient(top, white 0%, @grey-500 100%);
    background: linear-gradient(to bottom, white 0%, @grey-500 100%);
  }

  &:hover {
    background: @lightPink2;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmOWQ5MyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjlkOTMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, @lightPink2 0%, @lightPink2 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @lightPink2), color-stop(100%, @lightPink2));
    background: -webkit-linear-gradient(top, @lightPink2 0%, @lightPink2 100%);
    background: -o-linear-gradient(top, @lightPink2 0%, @lightPink2 100%);
    background: -ms-linear-gradient(top, @lightPink2 0%, @lightPink2 100%);
    background: linear-gradient(to bottom, @lightPink2 0%, @lightPink2 100%);

    &.disabled {
      background: @grayLight;
    }

    &:after {
      background: white;
    }
  }

  &.tumb-right {
    background: @lightGreen;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzUyZDRiNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM3OWRkZDciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, @lightGreen 0%, @lightGreen2 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @lightGreen), color-stop(100%, @lightGreen2));
    background: -webkit-linear-gradient(top, @lightGreen 0%, @lightGreen2 100%);
    background: -o-linear-gradient(top, @lightGreen 0%, @lightGreen2 100%);
    background: -ms-linear-gradient(top, @lightGreen 0%, @lightGreen2 100%);
    background: linear-gradient(to bottom, @lightGreen 0%, @lightGreen2 100%);

    &.disabled {
      background: @grayLight;
    }

    &:after {
      left: auto;
      right: 2px;
    }

    &:hover {
      background: @lightGreen3;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVmZTBkOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM1Y2RmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
      background: -moz-linear-gradient(top, @lightGreen3 0%, @lightGreen4 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @lightGreen3), color-stop(100%, @lightGreen4));
      background: -webkit-linear-gradient(top, @lightGreen3 0%, @lightGreen4 100%);
      background: -o-linear-gradient(top, @lightGreen3 0%, @lightGreen4 100%);
      background: -ms-linear-gradient(top, @lightGreen3 0%, @lightGreen4 100%);
      background: linear-gradient(to bottom, @lightGreen3 0%, @lightGreen4 100%);

      &.disabled {
        background: @grayLight;
      }
    }
  }
}

// Переключатель активности (Сегменты)

.style-condition-wrap {
  width: 13px;
  height: 15px;
  margin-top: 2px;
  .icons_svg(inline-block);
  .icons__error_light_red;
  cursor: pointer;

  &.active {
    width: 17px;
    .icons__ok_light_green;
  }
}

// Разморозить

.right-icon {
  padding: 9px 30px 9px 16px;
  .right-ico();

  &:after {
    .chevron-css(@blue-500, 2px, 7px);
    .chevron-dir-right();
    margin-right: 15px;
  }

  &.btn.white {
    border: none;
  }
}

.attachments-icon { // https://yadi.sk/i/0cRB5L-7oDBnv
  .icons_svg(inline-block);
  .icons__clip();
  width: 18px;
  height: 18px;
  vertical-align: baseline;

  &_red {
    .icons__clip_red();
  }
}

// Оповещения tumbler

.tumbler {
  .icons_svg(inline-block);
  position: relative;
  width: 20px;

  &.done {
    .icons__checkbox;
    height: 17px;
  }

  &.doing {
    .icons__checkbox_empty;
    height: 16px;
    margin-top: 1px;
  }

  &-checker {
    color: @grey-900;
    border-bottom: 1px dotted;
    cursor: pointer;

    &.opener {
      color: black;
    }
  }
}

// Иконка Info

.i-ico {
  .icons_svg(inline-block);
  .icons__i;
  width: 18px;
  height: 18px;
}

// История звонков

.phone-history-link {
  font-size: 11px;
  text-decoration: none;
  background-color: @grey-200;
  padding: 8px 13px 8px 34px;
  .nowrap;
  .border-radius(3px);
  .clearfix();
  .left-ico();

  &:before {
    .icons_svg();
    .icons__history_13x13_blue;
    width: 13px;
    height: 13px;
    margin-left: 10px;
  }

  span {
    float: left; // todo заменить на один спан
    border-bottom: 1px dotted;

    & + span {
      padding-left: 4px;
    }
  }

  &:hover {
    &:before {
      .icons__history_13x13_hover;
    }

    span {
      border-bottom-color: transparent;
    }
  }
}

// Показать 2 будущие задачи

#upload-btn, #tasks-show-btn {
  background: @blue-500;
  text-align: center;
  padding: 10px;
  display: block;
  text-decoration: none;

  &:before, > span {
    vertical-align: middle;
  }

  &:before {
    content: '';
    .icons_svg(inline-block);
    .icons__refresh;
    width: 20px;
    height: 20px;
  }

  > span {
    color: white;
    font-size: 13px;
    line-height: 18px;
    padding: 0 0 0 3px;
  }

  &.loading:before {
    .css-loader(@bg:white,@fs:16px);
    position: static;
    display: inline-block !important;
  }
}

.elements-upload-btn {
  .btn-base(@radius:0 0 3px 3px);
  text-align: center;
  padding: 21px;
  display: block;
  position: relative;

  &:before, > span {
    vertical-align: middle;
  }

  &:before {
    content: '';
    .icons_svg(inline-block);
    .icons__refresh;
    width: 13px;
    height: 15px;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    padding: 0 0 0 5px;
    position: static;
  }

  &__mute {
    position: absolute;
    top: 21px;
    left: 20px;
    color: @gray;
  }

  &__title {
    color: @blue-500;
  }

  &.loading:before {
    .css-loader(@bg:white,@fs:16px);
    position: static;
  }

  &:hover {
    background-color: @trHovBg;
  }
}

.float-right {
  float: right;
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
}

.form-reset-btn {
  border-bottom: dotted 1px;
  text-decoration: none;
  color: @link;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid;
  }
}

.link_user {
  padding-left: 16px;
  .left-ico();

  &:before {
    .icons_svg(inline-block);
    .icons__user();
    width: 10px;
    height: 12px;
  }

  &:hover:before {
    .icon__user_hover;
  }
}

.link_order {
  padding-left: 20px;
  .left-ico();

  &:before {
    .icons_svg(inline-block);
    .icon__cart();
    width: 14px;
    height: 11px;
  }

  &:hover:before {
    .icon__cart_hover;
  }
}

// блок Просмотренные / Ранее заказанные товары в карточке заказа (ссылка в блоке Состав заказа)

.purchased-icon {
  margin-left: 3px;
  margin-bottom: -2px;
  .icons_svg(inline-block);
  .icons__bag_red;
  width: 13px;
  height: 16px;

  &:hover {
    opacity: 0.5;
  }
}

.customer-products-link {
  padding-left: 23px;
  outline: 0 none;
  text-decoration: none;
  .left-ico();

  &:before {
    .icons_svg(block);
    .icons__bag_red;
    width: 13px;
    height: 16px;
  }

  > span {
    border-bottom: 1px dotted;
  }

  &:hover {
    text-decoration: none;

    > span {
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }
}

.btn_help {
  cursor: pointer;
  .icons_svg(inline-block);
  .icons_help_white;
  width: 15px;
  height: 15px;

  &:hover {
    .icons_help_hover;
  }

  &.inline {
    top: 3px;
    position: relative;
  }
}

// Товарная группа

.folder-link {
  position: absolute;
  top: 0;
  right: 0;
  display: block;

  .ico {
    .icons_svg(inline-block);
    .icons__open-folder;
    vertical-align: top;
    width: 18px;
    height: 14px;
  }
}

.date-icon, .kpi-date-icon {
  .icons_svg(inline-block);
  .icons-calendar-2();
  background-size: 100% auto;
  width: 12px;
  height: 13px;
  margin: 8px 0px 0px 4px;
  cursor: pointer;

  &:hover {
    .icons-calendar-2(@red-500);
  }

  & + .in {
    float: none;
    vertical-align: 2px;
    display: inline;
  }
}

.br-new {
  padding: 0 0 0 14px;
  .left-ico();

  &:before {
    .icons_svg(block);
    .icons__error_light_red;
    width: 9px;
    height: 9px;
  }
}

.warning-circle-icon {
  background-color: @blue-500;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  .border-radius(10px);
  font-weight: 400;
  color: white;
  line-height: 20px;
}

// 2 кнопки рядом

.btn-split {
  .clearfix();

  .btn {
    float: left;
  }

  .btn_split-hov {
    position: relative;
    float: right;
    margin-left: 8px;
  }

  .btn_split-hov:not(.disabled) {
    &:hover:enabled + .btn:enabled {
      background-color: @btnRedHov;
      border-right-color: @btnRedHov;
    }
  }
}

.btn-next {
  margin-left: 20px;
  float: left;
}

.no-edit {
  cursor: default;

  > div {
    cursor: default;
  }
}

// Кнопки отправки счета при оплате в заказе

.pay-send-btn {
  font-size: 12px;
  position: relative;
  padding-left: 20px;

  &.pay-send-btn_disabled {
    padding-left: 0;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    background-size: cover;
    margin-top: -5px;
  }

  span {
    border-bottom: 1px dotted @blue-500;
  }

  &:hover {
    color: @blue-500;
    text-decoration: none;

    span {
      border-bottom-style: solid;
    }
  }

  &_mail {
    &:before {
      .icons__pay-send_mail;
      width: 14px;
      height: 11px;
    }
  }

  &_sms {
    &:before {
      .icons__pay-send_sms;
      width: 15px;
      height: 13px;
    }
  }

  &_link {
    &:before {
      .icons__pay-send_link;
      width: 17px;
      height: 17px;
      margin-top: -9px;
    }
  }

  &_bill {
    &:before {
      .icons__bill_grey;
      width: 16px;
      height: 16px;
      margin-top: -8px;
    }
  }

  &_back {
    &:before {
      .icons__back_gray;
      width: 16px;
      height: 16px;
      margin-top: -8px;
    }
  }

  &_refresh {
    &:before {
      .icons__refresh_gray;
      width: 16px;
      height: 16px;
      margin-top: -8px;
    }
  }

  &_drop {
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: -16px;
      top: 50%;
      margin-top: -5px;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color: transparent @gray @gray transparent;
      transform: rotate(45deg);
    }
  }

  &_load {
    &:before {
      width: 12px;
      height: 12px;
      margin-top: -6px;
      .inline-css-loader();
    }
  }

  &_disabled {
    color: @custom !important;
    cursor: default;

    &:before {
      display: none;
    }

    &:hover {
      color: #aaa;
    }

    span {
      border-bottom: none;
    }
  }
}

.icon-lock-black {
    .icons_svg();
    .icons__lock_black();
    width: 11px;
    height: 13px;
    top: 3px;
    float: right;
    opacity: 0.6;
}

.info-btn {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 30px;
  min-width: 30px;
  height: 30px;

  &__switch {
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: 20px 14px;
    border: 1px solid rgba(0,0,0,0);
    width: 30px;
    height: 30px;
    margin: 0 !important;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }

    &:hover:not(.disabled), &_active {
      border-color: @bordBtn;
      background-color: white;
      border-radius: 3px;
    }

    &:hover:not(.disabled) {
      & + .info-btn__tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover:not(.disabled), &_active {
      border-color: @bordBtn;
      background-color: white;
      border-radius: 3px;
    }

    &:hover:not(.disabled)  {
      & + .info-btn__tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
    &:focus, &:active {
      outline: none;
    }

    &_duplicate {
      .product-separate-icon();

      &:hover {
        .product-separate-icon_hover();
      }
    }

    &_trash {
      .icons__del_little();

      &:hover, &.info-btn__switch_active {
        .icons__del_little_hover();
      }
    }

    &_separate {
      background-size: 22px 14px;
      .product-separate-qnt-icon();

      &:hover {
        .product-separate-qnt-icon_hover()
      }
    }

    &_menu {
      .icon-menu-swotcher();
    }

    &_plus {
      border-color: @bordBtn;
      background-color: white;
      border-radius: 3px;
      .icons__plus-22();
    }
    &_tag {
      background-repeat: no-repeat;
      background-position: center;

      &:active {
        background-color: @grayBg;
      }
    }
    &_add-tag {
      .icon-add-pin(@grey-700);
      background-size: 18px 16px;

      &:hover:not(.disabled) {
        .icon-add-pin(@blue-500);
      }
    }
    &_added-tag {
      .icon-added-pin(@grey-500);
      background-size: 10px 20px;
      border-color: @bordBtn;
      border-radius: 3px;

      &:hover:not(.disabled) {
        .icon-added-pin(@grey-600);
      }
    }
  }

  &__tooltip {
    position: absolute;
    top: calc(~"100% + 4px");
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 130px;
    padding: 3px 0;
    border-radius: 2px;
    color: white !important;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    background-color: @lightBlack;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
    margin-left: 0 !important;
    transition: 0.15s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 100;

    &_active {
      opacity: 1;
      visibility: visible;
    }

    &_ellipsis {
      padding-left: 4px;
      padding-right: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: auto;
    }
  }

  &_qnt {
    margin: 1px 7px 0 -2px !important;
    display: block;
  }
  &_rules {
    margin-top: -4px;
  }

  &-group {
    margin-top: 3px;

    &_product {
      margin-right: -5px;
    }
  }
}

.product-separate {
  &-wrap {
    &.active .info-btn__tooltip {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.product-duplicate-loading {
  display: inline-block;
  position: relative;
  margin: 8px 6px 0 0;

  &__icon {
    &:before {
      .loader;
      display: inline-block;
      content: '';
      width: 12px;
      height: 12px;
      vertical-align: middle;
      position: relative;
      border-color: @grayLight @blue-500 @grayLight @grayLight;
    }
  }
}

.pack-row + .product-separate-wrap {
  display: block;
  margin-top: -11px;
}

.order-product-list tr:not(.selected) .info-btn:not(.info-btn_menu),
.order-product-list tr.order-product-duplicate:not(.first-child) .info-btn:not(.info-btn_menu) {
  display: none;
}

.square-btn {
  display: inline-block;
  vertical-align: top;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  cursor: pointer;
  min-width: 30px;
  position: relative;
  border-radius: 3px;
  transition: 0.25s ease;

  &_apply {
    .apply-icon();
    background-size: 16px 12px;
    background-color: @messageGreen;

    &:hover {
      background-color: @darkGreen;
    }
  }
  &_cancel {
    .cancel-icon();
    background-size: 14px 14px;
    border: 1px solid @bordBtn;

    &:hover {
      .cancel-icon_hover();
      border-color: @blue-500;
    }
  }
  &_edit {
    .icon-gray-pencil;

    &:hover {
      background-color: @skyLight;
    }
  }

  &_invert {
    background-color: white;
    border: 1px solid @graySky;

    &.square-btn_apply {
      .apply-icon_green();
    }

    &:hover {
      background-color: @skyLight;
    }
  }

  &_edit {
    .icon-gray-pencil;

    &:hover {
      background-color: @skyLight;
    }
  }

  &_invert {
    background-color: white;
    border: 1px solid @graySky;

    &.square-btn_apply {
      .apply-icon_green();
    }

    &:hover {
      background-color: @skyLight;
    }
  }
}

.area-btn {
  display: inline-block;
  vertical-align: top;
  background: @grayLight3;
  border: 1px dashed fade(@grey-500, 20%);
  border-radius: 4px;
  padding: 15px;

  &__btn {
    display: inline-block;
    vertical-align: top;
    height: 40px;
    line-height: 40px;
    background-image: @blueGradient;
    background-color: @blue-500;
    padding: 0 42px 0 22px;
    position: relative;
    border-radius: 4px;
    font-size: 14px;
    color: white;
    transition: .25s ease;

    &::after {
      content: "";
      position: absolute;
      right: 30px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 7px;
      height: 7px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(45deg);
      border-radius: 2px;
    }

    &:hover {
      color: white;
      background-color: @blue-500;
    }
  }
}

/*
** Mini button width tool dropdown box
*/
.drop-tool-btn {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-top: -3px;

  &__switch {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid transparent;
    cursor: pointer;
    .icons_settings;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px;
    transition: all .25s ease, background-image 0s;

    &:hover {
      border-color: @bordBtn;
      background-color: white;
    }
  }
  &__tooltip {
    position: absolute;
    top: calc(~"100% + 3px");
    right: 0;
    padding: 8px;
    background: white;
    box-shadow: 0px 8px 16px rgba(26, 26, 26, 0.1);
    border-radius: 4px;
    width: 198px;
    display: none;
  }

  &_active {
    .drop-tool-btn__switch {
      border-color: @bordBtn;
      background-color: @bkFooterBord;
      .icons_settings_hover;
    }
    .drop-tool-btn__tooltip {
      display: block;
    }
  }
}

.btn_secondary {
  padding: 0 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 30px;
  text-align: center;
  color: @black-900;
  background: #fff;
  border: 1px solid @black-300;
  border-radius: 4px;

  &:hover {
    color: @black-900;
    background-color: @skyLight;
  }

  &:active {
    color: @black-900;
    background-color: @black-200;
  }

  &[disabled] {
    color: @grey-700;
    background-color: fade(#fff, 40%);

  }
}

.btn_size_l {
  padding: 0 20px;
  font-size: 18px;
  height: 54px;
}

.hover-icon {
  transition: .2s ease;

  &:hover {
    fill: @blue-500;
  }
  &_dark {
    fill: @grey-900;
  }
  &_inline {
    width: @spacing-s;
    height: @spacing-s;
    margin-left: @spacing-xxs;
    transform: translateY(2px);
  }
}

.in-new-link {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: @border-radius-s;
  border: 1px solid white;
  padding: @spacing-xxs;
  transition: .2s ease;

  &__icon {
    width: 19px;
    height: 19px;
    fill: white;
  }

  &:hover {
    background-color: white;

    .in-new-link__icon {
      fill: @black-500;
    }
  }
}

.dashed-link {
  display: inline-block;
  vertical-align: top;
  color: @blue-500;
  cursor: pointer;
  font-size: 0;
  max-width: 100%;

  &__icon {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    fill: currentColor;
    margin-right: @spacing-xxs;
    transition: .2s ease;

    svg {
      fill: currentColor;
      transition: .2s ease;
    }

    &_min {
      width: @spacing-s;
      height: @spacing-s;
      margin-top: 3px;
      margin-right: 1px;
    }
  }
  &__txt {
    color: @blue-500;
    .base-font (400, 14px, 19px);
    display: inline-block;
    vertical-align: top;
    transition: .2s ease;
    border-bottom: 1px dotted currentColor;
  }

  &:hover {
    color: @blue-600;
  }

  &_icon {
    color: @grey-900;
    .dashed-link__icon {
      margin-right: 0;
    }
  }
  &_dark {
    .dashed-link__txt {
      color: @blue-600;
    }

    .dashed-link__icon {
      fill: @blue-600;
    }

    &:hover {
      .dashed-link__txt {
        color: @blue-500;
      }

      .dashed-link__icon {
        fill: @blue-500;
      }
    }
  }
  &_white {
    .dashed-link__txt {
      color: white;
    }

    &:hover {
      .dashed-link__txt {
        color: @grey-200;
      }

      .dashed-link__icon {
        color: @grey-200;
      }
    }
  }
  &_ellipsis {
    white-space: nowrap;

    .dashed-link__txt {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}

.btn-icon {
  margin-right: @spacing-xs;
  width: 20px;
  height: 20px;
  fill: inherit;
  flex-shrink: 0;
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0;

  &__col {
    margin-left: 16px;
  }
}

.show-box-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: -2px;
}
.show-box-btn {
  display: flex;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;

  &__icon {
    width: 24px;
    height: 24px;
    fill: @blue-500;
    margin-right: 8px;
    transition: .2s ease;
  }

  &__title {
    .font-body;
    color: @blue-500;
    transition: .2s ease;
  }

  &:hover {
    .show-box-btn__icon {
      fill: @blue-600;
    }
    .show-box-btn__title {
      color: @blue-600;
    }
  }
}
.show-box-delimiter {
  margin: 20px 0;
  border-top: 1px dashed @grey-500;
  position: relative;

  &__icon {
    position: absolute;
    left: 0;
    right: 0;
    top: -12px;
    margin: auto;
    width: 24px;
    height: 24px;
    background: white;
    cursor: pointer;

    svg {
      fill: @grey-500;
      transition: .2s ease;
    }

    &:hover {
      svg {
        fill: @grey-900;
      }
    }
  }

  &_active {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.flash-btn {
  width: 16px !important;
  height: 16px !important;
  vertical-align: top !important;
  margin-top: @spacing-xxs;
  fill: @purple-500;
}
