///////////// Loaders

.overlay {
  &:before {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    content: '';
    z-index: 1880;
    overflow: hidden;
    position: absolute;
  }

  &.overlay-white:before {
    background-color: white;
    opacity: 0.8;
  }
}

.overpage {
  position: fixed;
  z-index: @overPageZind;

  &.o-bg, &.o-bg-black {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &.o-w {
    position: absolute;

    &.o-bg {
      background-color: white;
      opacity: 0.8;
      z-index: 1880;
    }
  }
}

.loading {
  .inner-wrapper {
    overflow: hidden;
  }
}

.o-loader {
  left: 50%;
  top: 50%;
  .css-loader();
}

.o-bg {
  background-color: @overlayBg;

  &-black {
    background-color: rgba(0, 0, 0, .6);
  }

  #head {
    background-color: transparent;
    opacity: 1.0;
  }

  .stat-box-wrapper &,
  .stat-box &,
  &.grid__tab {
    background-color: rgba(255, 255, 255, .6);
  }
}

.small-loader {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
  z-index: 20;
  .css-loader(@fs: 32px);

  &.input {
    font-size: 16px;
    left: 75%;
    top: 7px;

    &.l {
      left: 55%;
    }
  }

  &.right {
    left: 100%;
    margin-left: 2px;
  }
}

.small-static-loader {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  cursor: auto;
  .inline-css-loader();

  .input-group & {
    .ig-control-mix();
    margin-top: 5px;
  }

  .dimension-field + & {
    .ig-control-mix_dimenh();
  }

  .admin & {
    float: right;
    position: absolute;
  }

  .remind-area & {
    &.select-loader {
      left: 515px;
    }
  }

  &.delivery-city-loader {
    position: absolute;
    display: inline-block;
    margin-top: 5px;
    margin-left: 10px;
  }
}

/* CSS loader */

.loader {
  z-index: 20;

  .accompanying-body .for-scroll > & {
    position: fixed;
  }
}

#all-widgets-container .o-loader,
.black-red-loader:after, .loader,
.double_widget.loading .double_widget_btn:after,
.ajax-progress, .min-loader-after::after {
  .css-loader();
}

.min-loader-after {
  position: relative;
  &::after {
    content: '';
    right: -25px;
    left: auto;
    width: 18px;
    height: 18px;
  }
}

.loader_min {
  width: 20px;
  height: 20px;
}

.black-red-loader:after, .double_widget_btn:after {
  content: '';
  z-index: 2000;
}

.black-red-loader_small {
  &:after {
    width: 20px;
    height: 20px;
  }
}

#all-widgets-container {
  .overpage.o-loader {
    position: fixed;
  }

  .stat-box .overpage.o-loader {
    position: absolute;
  }
}

/** doubles **/

.small-loader-r {
  position: relative;
  float: left;
  top: 5px;
  margin-left: -25px;
  width: 20px;
  height: 20px;
  .css-loader(@fs: 18px);
  z-index: 20;
}

.customer-choose-field + .small-loader-r {
  position: static;
  margin-left: 8px;
  margin-top: 5px;
}

.small-input-loader {
  position: relative;
  &::after {
    content: '';
    .css-loader(@fs: 16px);
    left: auto;
    right: @spacing-xxs;
  }
}

.red-loader {
  .inline-css-loader();
  width: 12px;
  height: 12px;
  display: inline-block;

    &__wrapper {
      margin: 9px 0 0 4px;
    }
}

.ajax-progress {
  width: 21px;
  height: 21px;
  position: relative;
  vertical-align: middle;
  margin-left: 8px;
  display: none;
  z-index: 901;
}

.load-line {
  display: block;
  position: relative;
  background-image: linear-gradient(270deg, @loadLineStart -1.14%, @loadLineEnd 50%);
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.15) 30%, rgba(255, 255, 255, 0.65) 50%, rgba(255, 255, 255, 0.15) 70%, rgba(0, 0, 0, 0) 100%);
    animation: load-line-move 2s linear infinite;
  }
}

// мигание трубки

.blink-animation {
  .animation(blinking 1200ms ease 3);
}

// ¬ращение

@-webkit-keyframes spin {
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Blinking */

@-webkit-keyframes blinking {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@-moz-keyframes blinking {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@-o-keyframes blinking {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@-ms-keyframes blinking {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes blinking {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes load-line-move {
  from {
    left: -100%;
  }
  to {
    left: 200%;
  }
}

.overloadable {
  position: relative;
  user-select: none;

  &_overloaded::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url(../../img2/overloaded-pattern.png);
    z-index: 106;
  }
}

.content-loader {
  background: @overlayBg;
  position: fixed;
  top: 0;
  left: @fullNavBarWidth;
  right: 0;
  bottom: 0;
  border-top-left-radius: @spacing-s;
  display: none;
  z-index: 200;
  transition: left @toggle-nav-time linear;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__content {
    max-width: 435px;
  }
  &__title {
    .font-body-accent;
  }
  &__txt {
    .font-small;
    text-shadow: 0px 2px 4px rgba(30, 34, 72, 0.16);
    margin-top: 24px;
    text-align: center;
    color: white;
  }
  &__spinner {
    position: static;
  }

  .open-menu & {
    left: @fullNavPanelWidth;
  }
}
