.nb-r {
  border-right: 0 !important;
}

.nb-t {
  border-top: 0 !important;
}

.nb-b {
  border-bottom: 0 !important;
}

.nb {
  border: 0 !important;
}

.hid {
  overflow: hidden;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
  position: fixed !important;
}

.align-c {
  text-align: center !important;
}

.align-r {
  text-align: right !important;
}

.bold {
  .bold();
}

.min-w500 {
  min-width: 500px;
}

.max-w200 {
  max-width: 200px !important;
}

.max-w300 {
  max-width: 300px !important;
}

.max-w100p {
  max-width: 100% !important;
}

.w260 {
  width: 260px !important;
}

.w40 {
  width: 40px !important;
}

.w50 {
  width: 50px !important;
}

.w60 {
  width: 60px !important;
}

.w70 {
  width: 70px !important;
}

.w75 {
  width: 75px !important;
}

.w80 {
  width: 80px !important;
}

.w100 {
  width: 100px !important;
}

.w120 {
  width: 120px !important;
}

.w140 {
  width: 140px !important;
}

.w200 {
  width: 200px !important;
}

.w220 {
  width: 220px !important;
}

.w260 {
  width: 260px !important;
}

.w280 {
  width: 280px !important;
}

.w310 {
  width: 310px !important;
}

.w390 {
  width: 390px !important;
}

.w375 {
  width: 375px !important;
}

.w420 {
  width: 420px !important;
}

.w440 {
  width: 440px !important;
}

.w630 {
  width: 630px !important;
}

.w970 {
  width: 970px !important;
}

.w1p {
  width: 1%;
  white-space: nowrap;
}

.w25p {
  width: 25% !important;
}

.w30p {
  width: 30% !important;
}

.w70p {
  width: 70% !important;
}

.w100p {
  width: 100% !important;
}

.h110 {
    height: 110px !important;
}

.h140 {
  height: 140px !important;
}

.bt {
  border-top: 1px solid @bord !important;
}

.bb {
  border-bottom: 1px solid @bord !important;
}

.br {
  border-right: 1px solid @bord !important;
}

.bl {
  border-left: 1px solid @bord !important;
}

.bt-red {
  border-top: 2px solid @blue-500;
  .border-radius(0px 0px 5px 5px);
}

.bn {
  border: none !important;
}

.br50p {
  border-radius: 50%;
}

.elipsis {
  .ellipsis();
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-name-width {
  width: 160px;

  @media (min-width: @width2) {
    width: 200px;
  }
}

.s-name-width {
  width: 80px;

  @media (min-width: @width2) and (max-width: (@width3 - 1)) {
    width: 170px;
  }

  @media (min-width: @width3) {
    width: 160px;
  }
}

.clear {
  clear: both;
}

.cl {
  clear: both;

  &_none {
    clear: none !important;
  }
}

.border-box {
  .box-sizing(border-box);
}

.nowrap {
  white-space: nowrap;
}

.wrap {
    white-space: normal;
}

.table {
  display: table;
  width: 100%;
}

.table > .tr {
  display: table-row;
}

.td {
  display: table-cell;
}

.td-min {
  width: 1%;
  white-space: nowrap;
}

.upper {
  text-transform: uppercase;
}

/**************** !Helper *****************/

.zi9 {
  z-index: 9 !important;
}

.zi99 {
  z-index: 99 !important;
}

.zi999 {
  z-index: 999 !important;
}

.italic {
  font-style: italic !important;
}

.ur-hr-no:hover {
  border-bottom-color: transparent !important;
  text-decoration: none !important;
}

.db {
  display: block !important;
}

.di {
  display: inline !important;
}

.dib {
  display: inline-block !important;
}

.pa {
  position: absolute !important;
}

.pr {
  position: relative !important;
}

.wa {
  width: auto !important;
}

.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

.tac {
  text-align: center !important;
}

.m0 {
  margin: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.p5 {
  padding: 5px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.ft-lt {
  float: left;
}

.ft-rt {
  float: right;
}

.ft-none {
  float: none !important;
}

.fsn {
  font-style: normal !important;
}

// .modern {
.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs36 {
  font-size: 36px !important;
}

.lh12 {
  line-height: 12px !important;
}

.lh16 {
  line-height: 16px !important;
}

.lh18 {
  line-height: 18px !important;
}

.lh20 {
  line-height: 20px !important;
}

.lh25 {
  line-height: 25px !important;
}

.lh28 {
  line-height: 28px !important;
}

.lh36 {
  line-height: 36px !important;
}

.red {
  color: @gray3; //todo Поменять в шаблонах
}

.black {
  color: @black;
}

.white {
  color: white;
}

.gray {
  color: @gray !important;
}

.gray-link {
  color: @gray !important;
  border-bottom: 1px dotted;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid;
  }
}

.gray_dark {
  color: @grayDark;
}

.wt-smoke {
  color: @gray;
}

.green {
  color: @green;
}

// }

.dtl {
  border-bottom: 1px dotted;
  text-decoration: none;
  line-height: 1.4;

  &.checking {
    color: #005EEB;
    margin-right: 10px;
    cursor: pointer;
  }

  &:hover {
    text-decoration: none;
    border-bottom-style: solid;
  }
}

.text-danger {
  color: @blue-500;
}

.fill-blue {
  fill: @blue-500;
}

.popup-subhead .warning-circle-icon {
  margin: 8px 0 0 -15px;
}

.red-list {
  .none;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-top {
  vertical-align: top !important;
}

.disappeared, .disabled-simple {
  opacity: 0.5;
}

.disabled-shadow {
  margin-bottom: 0px !important;
  box-shadow: none !important;
}

.cleared {
  &.both {
    clear: both;
  }

  &.right {
    clear: right;
  }

  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

.clearfix {
  .clearfix();
}

.hide-md {
  @media (max-width: @width2 - 1px) {
    display: none !important;
  }
}

.show-md {
  display: none !important;

  @media (max-width: @width2 - 1px) {
    display: block !important;
  }
}

.show-md-flex {
  display: none !important;

  @media (max-width: @width2 - 1px) {
    display: flex !important;
  }
}
.line-through {
  text-decoration: line-through;
}

.flex {
  display: flex;
}

.flex-sb {
  justify-content: space-between;
}

.flex-center {
  justify-content: center !important;
}

.word-break-all {
  word-break: break-all;
}

.word-break-normal {
  word-break: normal !important;
}

.bg-color-inherit {
  background-color: inherit !important;
}

.no-shadow {
  box-shadow: none !important;
}

.rotate180 {
  -moz-transform: rotate(180deg); /* Для Firefox */
  -ms-transform: rotate(180deg); /* Для IE */
  -webkit-transform: rotate(180deg); /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg); /* Для Opera */
  transform: rotate(180deg);
}

.status {
  &-active {
    color: @green-600!important;
  }
  &-deactivated {
    color: @grey-700!important;
  }
  &-not-confirmed {
    color: @yellow-600!important;
  }
}

.mw0 {
  min-width: 0;
}
