@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
       url("../fonts/Gilroy/gilroy-700-normal.woff2") format("woff2"),
       url("../fonts/Gilroy/gilroy-700-normal.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: local('Gilroy'),
       url("../fonts/Gilroy/gilroy-800-normal.woff2") format("woff2"),
       url("../fonts/Gilroy/gilroy-800-normal.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "JetBrains Mono";
  src: local("JetBrains Mono"),
       url("../fonts/JetBrainsMono/jetbrains-mono-400-normal.woff2") format("woff2"),
       url("../fonts/JetBrainsMono/jetbrains-mono-400-normal.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
