/* ! GLOBALS */

html, body {
  height: 100%;
  .box-sizing(border-box);
}

body {
  font-family: @font-family-default;
  font-size: 14px;
  color: @black;
  background: @grey-100;
  line-height: 1.5;
}

*, *:before, *:after {
  .box-sizing(inherit);
}

#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -@footerH; /* the bottom margin is the negative value of the footer's height */
}

#push {
  height: @footerH; /* .push must be the same height as footer */
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  // .regular();
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 14px;
}

a {
  color: @link;
  text-decoration: none;
  outline: none;
  .norm-hov();
}

p + p {
  margin-top: 10px;
}

li {
  margin: 10px 0;
}

#logo {
  width: 890px;
  height: 199px;
  .icons_svg(block);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 36px;
  max-width: 90%;
  background-size: contain;
}

.save-box {
  width: auto;
}

.container_wite {
  @pLeft: 50px;
  @pRight: 90px;

  width: 590px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -590px/2;
  margin-top: -190px;
  background-color: white;
  .border-radius(3px);
  .box-shadow(0 1px 8px rgba(76, 19, 19, .08));

  h1 {
    border-bottom: 1px solid @bord;
    text-align: center;
    margin-bottom: 42px;
    padding-top: 25px;
    padding-bottom: 26px;
  }

  form.login, .info-content {
    padding-left: @pLeft;
    padding-right: @pRight;
    margin-bottom: 30px;
  }

  .form-horizontal {
    .center-info {
      margin-left: 30px;
    }

    .control-label {
      font-size: 17px;
      line-height: 20px;
      width: 130px;
      padding-top: 11px;
    }

    .controls {
      margin-left: 150px;
    }

    .control-group {
      &, &.small {
        margin-bottom: 30px;
      }

      &:last-child {
        border-top: 1px solid @bord;
        padding-top: 30px;
        padding-left: @pLeft;
        padding-right: @pRight;
        margin-left: -@pLeft;
        margin-right: -@pRight;
      }
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="number"],
    input[type="url"],
    select {
      height: 40px;
      width: 100%;
      margin-right: 0;
    }

    .btn {
      .btn-base();
      .btn-red(@blue-500);
      margin-bottom: 0;
      margin-right: 0;

      &.white {
        .btn-bord-mix();

        &.small {
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }

      font-size: 14px;
      padding-left: 31px;
      padding-right: 31px;
    }
  }

  @media (max-width: @phone) {
    padding: 0;
    max-width: 90%;
    margin: 0;
    transform: translate(-50%, -50%);

    h1 {
      padding: 16px;
      margin: 0;
    }

    form.login, .info-content {
      padding: 16px;
      margin: 0;
    }

    .form-horizontal {
      .control-label {
        width: 100%;
        padding: 0;
        float: none;
      }

      .controls {
        margin: 0;
      }

      .control-group {
        &, &.small {
          margin-bottom: 16px;
        }

        &:last-child {
          border-top: 1px solid @bord;
          padding: 16px 16px 0;
          margin-left: -16px;
          margin-right: -16px;
          margin-bottom: 0;
        }
      }
    }
  }
}

footer {
  .footer-mix();

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.forgot {
  display: inline-block;
  float: right;
  line-height: 40px;
  font-size: 13px;
}

.error {
  #logo {
    width: 242px;
    height: 54px;
    position: static;
    display: block;
    float: left;
    margin: 40px 0 40px 50px;
    transform: none;
  }

  .container {
    clear: both;

    &_wite {
      position: static;
      padding-bottom: 1px;
      margin: 0 50px;
      width: 50%;
      padding-left: 0;
      padding-right: 0;
      .border-radius(5px);

      h1 {
        text-align: inherit;
        padding-top: 19px;
        padding-bottom: 19px;
        padding-left: 30px;
        padding-right: 30px;
        .bold();
        margin-bottom: 20px;
      }

      .info-content {
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: @phone) {
    #logo {
      width: 890px;
      height: 199px;
      position: absolute;
      margin: 0;
      float: none;
    }

    .container_wite {
      width: 590px;
      position: absolute;
      margin: 0;
    }
  }
}
