.system-page {
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  padding: 180px 157px 96px;

  &_authorized {
    padding-left: 105px;
  }

  &__container {
    position: relative;
    max-width: 590px;
  }

  &__error-name {
    .font-small();
    margin-bottom: @spacing-m;
    letter-spacing: -0.15px;
  }

  &__title {
    .font-title-01-accent();
    font-family: @font-family-Gilroy;
    font-weight: 800;
    margin-bottom: @spacing-l;
    letter-spacing: 0.4px;
  }

  &__info {
    .font-paragraph();
    letter-spacing: -0.45px;
  }

  &__paragraph {
    margin-bottom: @spacing-s;
  }

  &__btn {
    margin-top: 64px;
    font-weight: 600;
  }

  &__decor {
    align-self: flex-end;
  }

  &__footer-text {
    position: absolute;
    margin: 0;
    bottom: 80px;
  }

  &__next {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
    .icon-chevron-left(#fff);
    background-size: cover;
    background-position: center center;
    transform: rotate(180deg);

    &:hover {
      background-color: @blue-900;
      .icon-chevron-left(#fff);
      background-position: center center;
      background-size: cover;
    }
  }
}

@media screen and (max-width: @tablet) {
  .system-page {
    padding: 0;
    flex-direction: column;
    justify-content: center;

    &__container {
      padding: @spacing-s;
      margin: 0 auto;
    }

    &__decor {
      margin: @spacing-xl auto 0;
      max-width: 400px;
      width: 100%;
    }

    &__decor-size {
      padding: 0 @spacing-xl;
    }

    &__footer-text {
      position: unset;
      width: 100%;
      padding: @spacing-l @spacing-s @spacing-s;
      max-width: 590px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: @phone) {
  .system-page {
    justify-content: flex-start;
  }
}
