// Icons
@import url(sprites.less);
// Flex mixins
@import url(flex.less);

// Mixins

.container() {
  //width: @width;
  width: 100%;
  margin: 0 auto;
  padding-left: @container-padding;
  padding-right: @container-padding;

  /*@media (min-width: @width2) and (max-width: (@width3 - 1)) {
    width: @width2 - @grid-gutter-width;
  }

  @media (min-width: @width3) {
    width: @width3 - @grid-gutter-width;
  }*/
}

.norm-hov() {
  &:hover {
    color: @hover;
    text-decoration: underline;
  }
}

.regular() {
  font-weight: normal;
  font-family: @font-family-default;
}

.bold() {
  // font-family: @font-family-bold;
  // font-weight: normal;
  font-weight: bold;
}

.clearfix() {
  &:before, &:after {
    content: " "; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

.ib-reset() {
  font-size: 0;
  line-height: 0;
  letter-spacing: -1px;
}

.ib-norm(@font:@fs,@lh:1,@ls:0) {
  font-size: @font;
  line-height: @lh;
  letter-spacing: @ls;
}

.text-hide(@pixels:1000em) {
  text-indent: @pixels;
  overflow: hidden;
  white-space: nowrap;
}

.text-show(@ind:0) {
  text-indent: @ind;
}

.ellipsis() {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis;
}

// ul, ol List reset

.none() {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Псеводоэлемент слева

.left-ico(@position:relative) {
  position: @position;

  &:before {
    content: '';
    .abs-left-ins();
  }
}

// Псеводоэлемент справа

.right-ico(@position:relative) {
  position: @position;

  &:after {
    content: '';
    .abs-right-ins();
  }
}

// Псеводоэлемент по центру

.center-ico(@position:relative) {
  position: @position;

  &:before {
    content: '';
    .abs-center-ins();
  }
}

// Выравнивание по центру

.abs-center-ins() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

// Выравание справа

.abs-right-ins(@right:0) {
  position: absolute;
  top: 0;
  left: auto;
  right: @right;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

// Выравание Слева

.abs-left-ins() {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

// Шевроны

.chevron-css(@bg,@bord,@size,@display:block) {
  border-top: 1px solid @bg;
  border-right: 1px solid @bg;
  border-width: @bord;
  display: @display;
  width: @size;
  height: @size;
}

.chevron-dir-right() {
  .rotate(45deg);
}

.chevron-dir-left() {
  .rotate(225deg);
}

.chevron-dir-top() {
  .rotate(-45deg);
}

.chevron-dir-bottom() {
  .rotate(135deg);
}

.chevron-css-square(@bord:2px, @size:8px, @color:@blue-500) {
  content: '';
  border-color: @color;
  border-style: solid;
  border-width: @bord @bord 0 0;
  position: absolute;
  height: @size;
  width: @size;
  right: -(@size/2 + @bord);
  top: @size*2;
  background: white;
  .rotate(45deg);
}

// Круг

.circle(@size) {
  width: @size;
  height: @size;
  .border-radius(50%);
}

// Плюс

.plus-mix(@bg:white,@height:2px,@width:10px) {
  position: relative;

  &:before, &:after {
    background-color: @bg;
    position: absolute;
    top: 50%;
    right: 0;
  }

  &:before {
    content: '';
    height: @width;
    width: @height;
    margin-top: -(@width/2);
    margin-right: (@width/2 - @height/2);
  }

  &:after {
    content: '';
    height: @height;
    width: @width;
    margin-top: -(@height/2);
  }
}

.minus-mix(@bg:white,@height:2px,@width:10px) {
  position: relative;

  &:before {
    content: '';
    background-color: @bg;
    position: absolute;
    top: 50%;
    right: 0;
    height: @height;
    width: @width;
    margin-top: -(@height/2);
  }
}

.plus-mix-left(@height:2px,@width:10px) {
  &:before, &:after {
    right: auto;
    left: 0;
  }

  &:before {
    margin-left: (@width/2 - @height/2);
  }
}

// Крест

.ico-times(@bg:@blue-500,@width:50%,@position:relative) {
  position: @position;

  &:before, &:after {
    content: '';
    position: absolute;
    width: @width;
    height: 1px;
    top: 1px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: (100% - @width)/2;
    background-color: @bg;
  }

  &:before {
    .rotate(-45deg);
  }

  &:after {
    .rotate(45deg);
  }
}

.placeholder-color(@col:@gray,@ff:@font-family-default) {
  ::-webkit-input-placeholder { /* WebKit browsers */
    color: @col;
    font-family: @ff;
  }

  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: @col;
    opacity: 1;
    font-family: @ff;
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: @col;
    opacity: 1;
    font-family: @ff;
  }

  input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: @col;
    font-family: @ff;
  }

  .my-placeholder {
    &, &.input-style {
      color: @col;
      font-family: @ff;
    }
  }
  /* Internet Explorer 9- */
}

.input-mix() {
  color: @black;
  border: 1px solid @inputBord;
  padding: 1px 10px;
  font-size: 13px;
  line-height: @inputH - 2;
  height: @inputH;
  .border-radius(3px);
  .box-shadow(inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08));
}

.input-empty() {
  height: @inputH;
  padding: 1px 10px;
  line-height: @inputH - 4;
  background: white;
  border: 1px solid @grayLight2;
  .border-radius(4px);
  .box-shadow(none);
}

.input-min() {
  width: @inputMinW;
  height: 22px;
  line-height: 20px;
  display: inline-block;
  text-align: right;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
}

.crud-input() {
  border: 1px solid @inputBord;
  background-color: white;
  padding-top: 4px;
  padding-bottom: 3px;
  .border-radius(3px);
  .box-shadow(inset 0 1px 3px rgba(0, 0, 0, .08));
}

.input-number-arrows-hide() {
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.controls-margin-mix() {
  margin-left: @labelW;

  @media (min-width: @width2) and (max-width: (@width3 - 1)) {
    margin-left: @labelW2;
  }

  @media (min-width: @width3) {
    margin-left: @labelW3;
  }
}

// Надписи справа от полей в edit

.ig-control-mix(@paddT:0,@paddL:0,@paddR:0) {
  position: absolute;
  right: auto;
  left: @labelW + @inputFieldW + @inputGroupPH;
  max-width: 200px;
  top: @inputGroupPT;
  margin-left: 11px;
  padding-top: @paddT;
  padding-left: @paddL;
  padding-right: @paddR;

  &.two-columns {
    // max-width: 150px;
  }

  @media (min-width: @width2) and (max-width: (@width3 - 1)) {
    // left: 540px;
    left: @labelW2 + @inputFieldW2 + @inputGroupPH2;
    max-width: 300px;
  }

  @media (min-width: @width3) {
    // left: 640px;
    max-width: 300px;
    left: @labelW3 + @inputFieldW3 + @inputGroupPH3;
  }
}

.ig-control-mix_dimenh() {
  left: @labelW + @inputDimenshW + @inputGroupPH;

  @media (min-width: @width2) and (max-width: (@width3 - 1)) {
    left: @labelW2 + @inputDimenshW2 + @inputGroupPH2;
  }

  @media (min-width: @width3) {
    left: @labelW3 + @inputDimenshW3 + @inputGroupPH3;
  }
}

.ig-control-mix_two-cols(@paddL:40px) {
  @labelWPadd: @labelW + @inputGroupPH - @paddL;

  position: relative;
  left: 0;
  top: 0;
  float: left;
  clear: both;
  padding: 10px 20px 10px @paddL;
  margin: 0 0 0 (@labelW + @inputGroupPH - @paddL);
  max-width: calc(~"50% - @{labelWPadd}");

  @media (min-width: @width2) and (max-width: (@width3 - 1)) {
    @labelWPadd2: @labelW2 + @inputGroupPH2 - @paddL;

    margin-left: @labelW2 + @inputGroupPH2 - @paddL;
    max-width: calc(~"50% - @{labelWPadd2}");
  }

  @media (min-width: @width3) {
    @labelWPadd3: @labelW3 + @inputGroupPH3 - @paddL;

    margin-left: @labelW3 + @inputGroupPH3 - @paddL;
    max-width: calc(~"50% - @{labelWPadd3}");
  }

  .two-columns__item & {
    max-width: 100%;
  }
}

// Стилизованные чекбоксы, радиобатоны

.hide-check() {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.hint-mix() {
  span {
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    cursor: help;
    position: relative;
  }

  i {
    font-style: normal;
    position: absolute;
    top: 100%;
    left: 0%;
    display: none;
    padding-top: 5px;
    z-index: 30;
  }

  i b {
    font-weight: normal;
    background: white;
    min-width: 120px;
    max-width: 150px;
    padding: 5px 5px;
    font-size: 11px;
    color: @grayDark;
    display: block;
    .border-radius(3px);
    .box-shadow(0 0 2px rgba(0, 0, 0, 0.4));
  }
}

////////////////////// Buttons /////////////////////////////////

.btn-mix(@display:inline-block) {
  cursor: pointer;
  display: @display;
  padding: 0;
  background: none;
  text-decoration: none;
  border: none;
  .user-select(none);

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &, &:focus {
    outline: none;
  }
}

.btn-base(@radius:6px, @fs:14px, @lh:20px, @height:36px) {
  .btn-mix();
  vertical-align: middle;
  //font-size: 12px;
  font-size: @fs;
  line-height: @lh;
  //height: 26px;
  height: @height;
  padding: 8px 20px;
  .regular;
  .border-radius(@radius);
}

.btn-disabled() {
  background-color: @grayLight;
  text-shadow: none;
  cursor: default;
  color: white;
}

.btn-none() {
  padding: 0;
  border: none;
  height: auto;
  .border-radius(0);

  &, &:focus {
    outline: none;
  }

  &, &:hover {
    background-color: transparent;
  }
}

.btn-bg-mix(@bg,@color:white,@hovBg,@actBg:darken(@bg,1%)) {
  background-color: @bg;

  &, &:hover, &:active {
    color: white;
  }

  &:hover {
    background-color: @hovBg;
  }

  &:active {
    background-color: @actBg;
  }

  &[disabled], &[disabled]:hover, &.disabled, &.disabled:hover {
    .btn-disabled();
  }
}

.btn-bord-mix(@bg:white,@bord:@grayLight,@color:@black-500,@hovBg:@grey-200,@actBg:@grayVeryLight) {
  background-color: @bg;
  border: 1px solid @grey-500;
  background-image: @tretieryGradient;
  text-shadow: none;

  &, &:hover, &:active {
    color: @color;
    text-decoration: none;
  }

  &:hover {
    background-image: none;
    background: @grey-200;
  }

  &:active {
    background-image: none;
    background: @grey-500;
    border-color: @grey-700;
  }

  &[disabled], &[disabled]:hover, &.disabled, &.disabled:hover {
    cursor: default;
    background: @grey-200;
    border-color: @grey-500;
    color: @grey-700;
  }
}

.btn-bord-simple-mix(@bg:white,@bord:#cccccc,@color:@black,@hovBg:#f6f6f6,@actBg:#eeeeee) {
  /*
  ** Исходный миксин для стилизации белых кнопок
   */
  background-color: @bg;
  border: 1px solid @bord;
  text-shadow: none;

  &, &:hover, &:active {
    color: @color;
  }

  &:hover {
    background-color: @hovBg;
  }

  &:active {
    background-color: @actBg;
  }

  &[disabled], &[disabled]:hover, &.disabled, &.disabled:hover {
    .btn-disabled();
  }
}

.btn-red(@bg:@blue-500) {
  //.btn-bg-mix(@bg:@blue-500,@color:white,@hovBg:@btnRedHov,@actBg:@btnRedAct);
  background-image: @blueGradient;
  background-color: @blue-500;
  color: white;
  font-weight: 500;

  &:hover {
    background-image: none;
    background: @blue-500;
    color: white;
    text-decoration: none;
  }
  &:active {
    background-image: none;
    background-color: @blue-600;
  }

  &[disabled], &[disabled]:hover, &.disabled, &.disabled:hover {
    cursor: default;
    background: @grey-500;
    color: @grey-900;
  }
}
.btn-secondary() {
  background-image: @tretieryGradient;
  border: 1px solid #DEE2E6;
}

.btn-green() {
  .btn-bg-mix(@brightGreen, white, @btnGreen);
}

.btn-square(@size:@btnS, @br:3px) {
  width: @size;
  height: @size;
  padding: 0;
  .text-hide();
  .border-radius(@br);
}

.btn-plus-mix() {
  .btn-square();
  .center-ico();

  &:before {
    .icons_svg;
    .icons__plus-22;
    width: 10px;
    height: 10px;
  }
}

.tabs-bord-mix() {
  .none;
  margin-left: 20px;
  margin-bottom: -1px;

  > li {
    display: inline-block;
    margin-right: 20px;
    padding: 0;
    font-weight: normal;
    vertical-align: bottom;

    &:last-child {
      margin-right: 0;
    }

    a {
      .box-sizing(border-box);
      display: block;
      border-bottom: 1px solid transparent;
    }

    &.active a {
      color: @black;
      border-bottom-color: @blue-500;
    }
  }
}

.change-position-btn() {
  .btn-mix();
  .btn-none();
  .text-hide();
  .icon-chevron-left(@grayLight);
  background-position: center center;
  width: 24px;
  height: 24px;
  margin: 0 10px;

  &:hover {
    .icon-chevron-left(@blue-500);
  }
}

.change-position-btn_up() {
  .rotate(90deg);
}

.change-position-btn_down() {
  .rotate(-90deg);
}

.footer-mix() {
  height: @footerH;
  line-height: @footerH;
  color: @gray;
  font-size: 11px;
  padding: 0;
  clear: both;

  > .container > div {
    .clearfix;
  }
}

.dropdown__block(@bg:white,@bord:@inputBord) {
  .box-shadow(0 3px 15px rgba(0, 0, 0, .12));
  .border-radius(@dropDRad);
  background-color: @bg;
  border: 1px solid @bord;
}

.dropdown__block-arrow(@bord1:@inputBord, @bord2:white,@left:10px) {
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    left: @left;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    z-index: 1;
  }

  &:before {
    border-width: 0 5.5px 6px 5.5px;
    border-bottom-color: @bord1;
  }

  &:after {
    border-width: 0 4.5px 5px 4.5px;
    border-bottom-color: @bord2;
    margin-left: 1px;
  }
}

.dropdown__block-arrow_down(@top:6px) {
  top: 100%;
  margin-top: @top;

  &:before, &:after {
    bottom: 100%;
    top: auto;
  }
}

.dropdown__block-arrow_up() {
  bottom: 100%;
  margin-bottom: 6px;

  &:before, &:after {
    border-width: 0 0 1px 1px;
    top: 100%;
    bottom: auto;
  }

  &:before {
    border-width: 6px 5px 0 5px;
    border-color: @inputBord transparent transparent transparent;
  }

  &:after {
    border-width: 5px 4.5px 0 4.5px;
    border-color: white transparent transparent transparent;
  }
}

.dropdown__block-arrow_right() {
  left: auto;
  right: 0;

  &:before, &:after {
    left: auto;
    right: 10px;
  }

  &:after {
    margin-right: 1px;
  }
}

.dropdown__block-arrow_left() {
  right: auto;
  left: 100%;
  top: 0;
  margin-top: -1px;
  margin-left: 6px;

  &:before, &:after {
    left: auto;
    right: 100%;
    bottom: auto;
    top: 10px;
    margin-right: -2px;
    .rotate(-90deg);
  }

  &:after {
    margin-top: 0.5px;
  }
}

.dropdown-hover-levels() {
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;

  &:hover {

    > ul {
      display: block !important;
    }
  }

  &.down > ul {
    margin-top: 0;
  }

  &.dropdown-children {
    display: block;
    padding-top: 0;
    padding-bottom: 0;

    &.left > ul {
      margin-left: 0;
    }
  }
}

.common-label-mix() {
  color: @grey-900;
  font-size: 13px;
  line-height: @lh;
  float: left;
  display: block;
  width: @labelW;
  font-style: normal;
  padding: 6px 10px 0 0;

  @media (min-width: @width2) and (max-width: (@width3 - 1)) {
    width: @labelW2;
  }

  @media (min-width: @width3) {
    width: @labelW3;
  }
}

.filter-cancel-btn() {
  font-size: 11px;
  color: @grayVeryDark;
  float: left;
  margin: 2px 30px 0 0;
  cursor: pointer;
}

.status-bar-item() {
  cursor: pointer;
  list-style: none;
  padding: 5px 7px 5px 12px;
  position: relative;
  z-index: 99;

  &.active {
    background-color: @blue-500;
    .border-radius(4px);

    &:not(:last-child) {
      border-radius: 4px 4px 0 0;
    }

    &:not(:first-child) {
      border-radius: 0 0 4px 4px;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
}

.status-bar-item-hov() {
  background-color: @blue-500;
}

.status-bar-link() {
  color: @link;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;

  &.no-italic {
    .regular;
  }
}

.status-bar-link-act() {
  color: white;
}

.fade-out() {
  opacity: 0;
  .transition(opacity 200ms ease);
}

.fade-in() {
  opacity: 1;
}

// Треугольник в селекте

.select-trigger() {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
}

.select-trigger__arrow() {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 3.5px 0 3.5px;
  border-color: @black transparent transparent transparent;
  overflow: hidden;
  opacity: 1;
  filter: alpha(opacity=100);
}

.ico-burger(@width:12px, @height:1px, @mar:3px, @marR: 10px) {
  position: relative;
  display: inline-block;
  width: @width;
  vertical-align: middle;
  margin-right: @marR;
  margin-top: @height + @mar - 1;
  margin-bottom: @height + @mar;

  &, &:before, &:after {
    height: @height;
    background-color: @blue-500;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
  }

  &:before {
    top: -@mar;
  }

  &:after {
    bottom: -@mar;
  }
}

// Список с дефисами

.dash-list-mix(@color:@blue-500) {
  .none;

  li {
    margin: 0 0 8px 0;

    &:before {
      content: '\2014 \00a0';
      color: @color;
    }
  }
}

// Иконка статус заказа

.status-ico() {
  position: absolute;
  top: 50%;
  left: 13px;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

/* !STATUS GROUP STYLES */

.status-icons-list() {
  &.delivery {
    color: @blue-700;
    background: fade(@blue-500, 13%);

    .img-st {
      .icon-delivery(@blue-600);
    }

    .tbl-st-txt {
      color: @blue-600;
    }
  }

  &.delivering {
    //background: @delivering;
    background: none !important;

    .img-st {
      .icons__stat_4;
      height: 14px;
      width: 22px;
      margin-right: 1px;
    }
  }

  &.new {
    color: #fff;
    background: @yellow-500;

    .img-st {
      .icon-light-on(#fff);
    }
  }

  &.cancel {
    color: @red-700;
    background: fade(@red-500, 15%);

    .img-st {
      .icon-clear-circle-outlined(@red-600);
    }
  }

  &.complete {
    color: @green-800;
    background: fade(@green-500, 15%);

    .img-st {
      .icon-checkmark-circle-outlined(@green-700);
    }

    &_light {
      min-width: 0;
      height: 24px;
      line-height: 24px;
      padding: 0 8px;

      .img-st {
        .icons__ok_light_green();
        height: 12px;
        width: 12px;
        margin-bottom: 2px;
      }

      .tbl-st-txt {
        padding-left: 5px;
        vertical-align: middle;
      }
    }
  }

  &.assembling {
    color: @purple-600;
    background: fade(@purple-500, 10%);

    .img-st {
      .icon-download-outlined(@purple-500);
    }
  }

  &.approval {
    color: @yellow-800;
    background: fade(@yellow-500, 20%);

    .img-st {
      .icon-comment-outlined(@yellow-700)
    }
  }

  &.custom {
    color: @custom-status-text-color;
    background: @custom-status-bg-color;

    .img-st {
      .icon-user-outlined(@custom-status-text-color);
    }

    .st-text {
      color: inherit;
    }
  }

  &.custom-status-color-0 {
    color: @custom-status-text-color-0;
    background-color: @custom-status-bg-color-0;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-0);
    }
  }

  &.custom-status-color-1 {
    color: @custom-status-text-color-1;
    background-color: @custom-status-bg-color-1;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-1);
    }
  }

  &.custom-status-color-2 {
    color: @custom-status-text-color-2;
    background-color: @custom-status-bg-color-2;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-2);
    }
  }

  &.custom-status-color-3 {
    color: @custom-status-text-color-3;
    background-color: @custom-status-bg-color-3;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-3);
    }
  }

  &.custom-status-color-4 {
    color: @custom-status-text-color-4;
    background-color: @custom-status-bg-color-4;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-4);
    }
  }

  &.custom-status-color-5 {
    color: @custom-status-text-color-5;
    background-color: @custom-status-bg-color-5;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-5);
    }
  }

  &.custom-status-color-6 {
    color: @custom-status-text-color-6;
    background-color: @custom-status-bg-color-6;
    border: 1px solid @custom-status-border-color-6;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-6);
    }
  }

  &.custom-status-color-7 {
    color: @custom-status-text-color-7;
    background-color: @custom-status-bg-color-7;
    border: 1px solid @custom-status-border-color-7;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-7);
    }
  }

  &.custom-status-color-8 {
    color: @custom-status-text-color-8;
    background-color: @custom-status-bg-color-8;
    border: 1px solid @custom-status-border-color-8;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-8);
    }
  }

  &.custom-status-color-9 {
    color: @custom-status-text-color-9;
    background-color: @custom-status-bg-color-9;
    border: 1px solid @custom-status-border-color-9;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-9);
    }
  }

  &.custom-status-color-10 {
    color: @custom-status-text-color-10;
    background-color: @custom-status-bg-color-10;
    border: 1px solid @custom-status-border-color-10;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-10);
    }
  }

  &.custom-status-color-11 {
    color: @custom-status-text-color-11;
    background-color: @custom-status-bg-color-11;
    border: 1px solid @custom-status-border-color-11;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-11);
    }
  }

  &.custom-status-color-12 {
    color: @custom-status-text-color-12;
    background-color: @custom-status-bg-color-12;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-12);
    }
  }

  &.custom-status-color-13 {
    color: @custom-status-text-color-13;
    background-color: @custom-status-bg-color-13;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-13);
    }
  }

  &.custom-status-color-14 {
    color: @custom-status-text-color-14;
    background-color: @custom-status-bg-color-14;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-14);
    }
  }

  &.custom-status-color-15 {
    color: @custom-status-text-color-15;
    background-color: @custom-status-bg-color-15;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-15);
    }
  }

  &.custom-status-color-16 {
    color: @custom-status-text-color-16;
    background-color: @custom-status-bg-color-16;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-16);
    }
  }

  &.custom-status-color-17 {
    color: @custom-status-text-color-17;
    background-color: @custom-status-bg-color-17;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-17);
    }
  }

  &.custom-status-color-18 {
    color: @custom-status-text-color-18;
    background-color: @custom-status-bg-color-18;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-18);
    }
  }

  &.custom-status-color-19 {
    color: @custom-status-text-color-19;
    background-color: @custom-status-bg-color-19;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-19);
    }
  }

  &.custom-status-color-20 {
    color: @custom-status-text-color-20;
    background-color: @custom-status-bg-color-20;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-20);
    }
  }

  &.custom-status-color-21 {
    color: @custom-status-text-color-21;
    background-color: @custom-status-bg-color-21;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-21);
    }
  }

  &.custom-status-color-22 {
    color: @custom-status-text-color-22;
    background-color: @custom-status-bg-color-22;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-22);
    }
  }

  &.custom-status-color-23 {
    color: @custom-status-text-color-23;
    background-color: @custom-status-bg-color-23;

    .img-st {
      .icon-user-outlined(@custom-status-icon-color-23);
    }
  }

  &.success{
    color: white;
    background-color: @green-500;

    .img-st {
      .checkmark_circle_outlined();
    }
  }

  &.error{
    color: white;
    background-color: @red-500;

    .img-st {
      .clear_circle_outlined(#ffffff);
    }
  }

  &.created{
    color: white;
    background-color: @yellow-500;

    .img-st {
      .icon-remaining-time(#ffffff);
    }
  }

  &.expired{
    color: white;
    background-color: @grey-700;

    .img-st {
      .icon-timer_off();
    }
  }
}

.icon-ok(@sizeX:15px,@sizeY:15px,@display:inline-block) {
  width: @sizeX;
  height: @sizeY;
  .icons_svg(@display);
  .icons__ok_light_green;
}

.icon-cancel(@sizeX:15px,@sizeY:15px,@display:inline-block) {
  width: @sizeX;
  height: @sizeY;
  .icons_svg(@display);
  .icon-action-clear(@red-500);
}

.bottom-bar-mix(@bottom:@fixedBarBott) {
  position: fixed;
  bottom: @bottom;
  left: @fullNavBarWidth;
  right: 0;
  z-index: @bottomBarZind;
  text-align: center;
  .container();

  .open-menu & {
    left: @fullNavPanelWidth;
  }

  .no-menu & {
    left: @navBarWidth;
    transition: all 0s !important;
  }

  transition: left @toggle-nav-time linear !important;

  .show-menu & {
    transition: left 0s !important;
  }
}

.bottom-bar-grad(@gradWidth:percentage(@width3/@width), @gradHeight:percentage(65/46)) {
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: @gradWidth;
    height: percentage(65/46);
    bottom: -5px;
    left: -(@gradWidth - 100%)/2;
    background-color: rgba(0, 0, 0, 0);
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .13) 60%, rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, .13) 60%, rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, .13) 60%, rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(bottom, rgba(0, 0, 0, .13) 60%, rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, .13) 60%, rgba(0, 0, 0, 0));
  }
}

// Css loader

.inline-css-loader(@bord:2px,@bg:@grayVeryLight,@fs:16px) {
  background: none;
  border: @bord solid @bg;
  font-size: @fs;
  width: 1em;
  height: 1em;
  border-top-color: @blue-500;
  margin: auto;
  display: block;
  .border-radius(.5em);
  .box-sizing(border-box);
  .animation(spin 1s linear infinite);
}

.css-loader(@bord:2px,@bg:@grayVeryLight,@fs:40px) {
  background: none;
  border: @bord solid @bg;
  font-size: @fs;
  width: 1em;
  height: 1em;
  border-top-color: @blue-500;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  .border-radius(.5em);
  .box-sizing(border-box);
  .animation(spin 1s linear infinite);
}

// Tabs in popups

.pop-tabs() {
  background: none;
  list-style: none;

  li {
    font-size: 18px;
    border-width: 0 0 1px;
    border-style: solid;
    border-bottom-color: transparent;
    color: @link;
    padding: 0;
    .border-radius(0);

    &, &:hover {
      background: none;
    }

    &, &.active {
      margin: 0 20px -1px 0;
      .box-shadow(none);

      a {
        padding: 0;
        display: inline-block;
        vertical-align: bottom;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.active {
      margin-bottom: 0;
      padding-bottom: 0;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid @blue-500 !important;

      a {
        color: black;
      }
    }
  }
}

// Multiline ellipsis (also known as line clamp)

.multiline-ellipsis(@linesCount: 2, @lineHeight: 15px) {
  display: -webkit-box;
  -webkit-line-clamp: @linesCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // IE doesn't support clamp
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-height: @linesCount * @lineHeight;
  }
}

.reset-number-input() {
  padding: 0 16px;
  outline: none;
  height: 30px;
  .font-small;
  color: @black-500;
  border-radius: 4px;
  border: 1px solid @grey-500;

  &::placeholder {
    color: @grey-600;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[disabled], &[readonly] {
    color: @grey-600;
    background-color: @grey-200 !important;
    border-color: @grey-200 !important;
    pointer-events: none;
  }

  &:hover:not(&_error) {
    border-color: @grey-700;
  }

  &:focus:not(&_error) {
    border-color: @blue-500;
    box-shadow: 0 0 0 2px @blue-100;
  }

  &_error {
    border-color: @red-500;
    box-shadow: 0 0 0 2px @red-100;
  }
}

.nav-without-transition () {
  .nav-without-transition & {
    transition: none !important;
  }
}

// Titles
.base-font (@fw, @fs, @lh) {
  font-weight: @fw;
	font-size: @fs;
	line-height: @lh;
}

.font-hero () {
  .base-font(500, 64px, (70/64));
}
.font-title-01 () {
  .base-font(500, 40px, (44/40));
}
.font-title-01-accent () {
  .base-font(800, 40px, (44/40));
}
.font-title-02 () {
  .base-font(400, 24px, (32/24));
}
.font-title-02-accent () {
  .base-font(500, 24px, (32/24));
}
.font-article () {
  .base-font(400, 20px, (32/20));
}
.font-article-accent () {
  .base-font(500, 20px, (32/20));
}
.font-paragraph () {
  .base-font(400, 18px, (28/18));
}
.font-paragraph-accent () {
  .base-font(500, 18px, (28/18));
}
.font-body () {
  .base-font(400, 16px, (24/16));
}
.font-body-accent () {
  .base-font(500, 16px, (24/16));
}
.font-small () {
  .base-font(400, 14px, (20/14));
}
.font-small-accent () {
  .base-font(500, 14px, (20/14));
}
.font-tiny () {
  .base-font(400, 12px, (14/12));
}
.font-tiny-accent () {
  .base-font(500, 12px, (14/12));
}
