.border-radius (@string) {
  -webkit-border-radius: @string;
  -moz-border-radius: @string;
  border-radius: @string;
  // -moz-background-clip: padding;
  // -webkit-background-clip: padding-box;
  // background-clip: padding-box;
}

// Single side border-radius
.border-top-radius(@radius) {
  -webkit-border-top-right-radius: @radius;
  -webkit-border-top-left-radius: @radius;
  -moz-border-top-right-radius: @radius;
  -moz-border-top-left-radius: @radius;
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
  -webkit-border-top-right-radius: @radius;
  -moz-border-bottom-right-radius: @radius;
  -moz-border-top-right-radius: @radius;
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
  -webkit-border-bottom-left-radius: @radius;
  -moz-border-bottom-right-radius: @radius;
  -moz-border-bottom-left-radius: @radius;
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
  -webkit-border-top-left-radius: @radius;
  -moz-border-bottom-left-radius: @radius;
  -moz-border-top-left-radius: @radius;
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

.border-top-left-radius(@radius) {
  -webkit-border-top-left-radius: @radius;
  -moz-border-top-left-radius: @radius;
  border-top-left-radius: @radius;
}

.border-top-right-radius(@radius) {
  -webkit-border-top-right-radius: @radius;
  -moz-border-top-right-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
  -moz-border-bottom-left-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-bottom-right-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
  -moz-border-bottom-right-radius: @radius;
  border-bottom-right-radius: @radius;
}

.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow: @string;
  box-shadow: @string;
}

.box-sizing(@string) {
  -webkit-box-sizing: @string;
  -moz-box-sizing: @string;
  box-sizing: @string;
}

// User select
// For selecting text on the page
.user-select(@select) {
  -webkit-user-select: @select;
  -moz-user-select: @select;
  -ms-user-select: @select; // IE10+
  user-select: @select;
}

.transition(@transition) {
  -webkit-transition: @transition;
  -o-transition: @transition;
  -ms-transition: @transition;
  -moz-transition: @transition;
  transition: @transition;
}

.transition-duration(@duration) {
  -webkit-transition-duration: @duration;
  -o-transition-duration: @duration;
  -ms-transition-duration: @duration;
  -moz-transition-duration: @duration;
  transition-duration: @duration;
}

.transition-delay(@delay) {
  -webkit-transition-delay: @delay;
  -o-transition-delay: @delay;
  -ms-transition-delay: @delay;
  -moz-transition-delay: @delay;
  transition-delay: @delay;
}

.transition-timing-function(@timing-function) {
  -webkit-transition-timing-function: @timing-function;
  -o-transition-timing-function: @timing-function;
  -ms-transition-timing-function: @timing-function;
  -moz-transition-timing-function: @timing-function;
  transition-timing-function: @timing-function;
}

.animation(@animation) {
  -webkit-animation: @animation;
  -moz-animation: @animation;
  -ms-animation: @animation;
  -o-animation: @animation;
  animation: @animation;
}

.animation-duration(@animation) {
  -webkit-animation-duration: @animation;
  -moz-animation-duration: @animation;
  -ms-animation-duration: @animation;
  -o-animation-duration: @animation;
  animation-duration: @animation;
}

.animation-name(@animation) {
  -webkit-animation-name: @animation;
  -moz-animation-name: @animation;
  -ms-animation-name: @animation;
  -o-animation-name: @animation;
  animation-name: @animation;
}

.background-size(@background-size) {
  -webkit-background-size: @background-size;
  -o-background-size: @background-size;
  -ms-background-size: @background-size;
  -moz-background-size: @background-size;
  background-size: @background-size;
}

.transform(@transform) {
  -webkit-transform: @transform;
  -moz-transform: @transform;
  -ms-transform: @transform;
  -o-transform: @transform;
  transform: @transform;
}

.transform-origin(@transform) {
  -webkit-transform-origin: @transform;
  transform-origin: @transform;
}

.transform-style(@transform) {
  -webkit-transform-style: @transform;
  transform-style: @transform;
}

.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  transform: rotate(@deg);
}

.scale(@x,@y) {
  -webkit-transform: scale(@x, @y);
  -moz-transform: scale(@x, @y);
  -o-transform: scale(@x, @y);
  -ms-transform: scale(@x, @y);
  transform: scale(@x, @y);
}

.translate(@x,@y) {
  -webkit-transform: translate(@x, @y);
  -moz-transform: translate(@x, @y);
  -o-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y);
  transform: translate(@x, @y);
}

.backface-visibility(@vis) {
  -webkit-backface-visibility: @vis;
  -moz-backface-visibility: @vis;
  -ms-backface-visibility: @vis;
}

.columns(@colwidth: 250px, @colcount: 0, @colgap: 0px, @columnRuleColor: transparent, @columnRuleStyle: solid, @columnRuleWidth: 0) {
  -moz-column-width: @colwidth;
  -moz-column-count: @colcount;
  -moz-column-gap: @colgap;
  -moz-column-rule-color: @columnRuleColor;
  -moz-column-rule-style: @columnRuleStyle;
  -moz-column-rule-width: @columnRuleWidth;
  -webkit-column-width: @colwidth;
  -webkit-column-count: @colcount;
  -webkit-column-gap: @colgap;
  -webkit-column-rule-color: @columnRuleColor;
  -webkit-column-rule-style: @columnRuleStyle;
  -webkit-column-rule-width: @columnRuleWidth;
  column-width: @colwidth;
  column-count: @colcount;
  column-gap: @colgap;
  column-rule-color: @columnRuleColor;
  column-rule-style: @columnRuleStyle;
  column-rule-width: @columnRuleWidth;
}

.columns-mix(@columns) {
  -webkit-columns: @columns;
  -moz-columns: @columns;
  -ms-columns: @columns;
  -o-columns: @columns;
  columns: @columns;
}

.column-fill(@fill) {
  -moz-column-fill: auto;
  column-fill: auto;
}

.column-gap(@gap) {
  webkit-column-gap: @gap;
  -moz-column-gap: @gap;
  -ms-column-gap: @gap;
  -o-column-gap: @gap;
  column-gap: @gap;
}

.gradient (@startColor: @grayVeryLight, @endColor: white) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@startColor', endColorstr='@endColor', GradientType=0); /* IE6-9 */
}

.gradient-left-opacity (@startColor: @grayVeryLight) {
  background: -moz-linear-gradient(left, @startColor 0%, fadeout(@startColor, 100%) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, @startColor), color-stop(100%, fadeout(@startColor, 100%))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, @startColor 0%, fadeout(@startColor, 100%) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, @startColor 0%, fadeout(@startColor, 100%) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, @startColor 0%, fadeout(@startColor, 100%) 100%); /* IE10+ */
  background: linear-gradient(to right, @startColor 0%, fadeout(@startColor, 100%) 100%); /* W3C */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
}

.gradient-bottom-opacity (@startColor: white) {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, @startColor 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, @startColor 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, @startColor 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}